<template>
  <div class="navigation-list">
    <span
      v-for="(item, index) in activeWebsiteList"
      :key="index"
      @click="() => selectDown(item)"
      class="item"
    >
      <div class="card">
        <website-nav-svg
          :animationData="item.animationData"
          :width="item.width"
          :height="item.height"
        />
        <p class="name">{{ item.name }}</p>
        <span v-if="item.badgetCount && item.badgetCount > 0" class="badget">{{
          item.badgetCount
        }}</span>
      </div>
    </span>
  </div>
</template>

<script>
import WebsiteNavSvg from "./WebsiteNavSvg";
import { WEBSITE_LIST } from "./utils";
import { bindUnique } from "./api/user";
import { v4 as uuidV4 } from "uuid";

export default {
  props: {
    unique: {
      type: String,
      default: "",
    },
  },
  components: {
    WebsiteNavSvg,
  },

  data() {
    return {
      websites: WEBSITE_LIST,
    };
  },

  computed: {
    permission() {
      const permission = localStorage.getItem("WebsitePermission");
      return permission ? permission.split(",") : [];
    },
    activeWebsiteList() {
      return this.websites.filter((item) => {
        return this.permission.includes(item.key);
      });
    },
  },

  mounted() {
    window.sessionStorage.setItem("loginRedirt", "true");
  },

  methods: {
    handleRemind() {
      this.$message("项目开发中，敬请期待");
    },
    selectDown(item) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const unique = urlParams.get("unique") || uuidV4(16);
      if (unique) {
        bindUnique({ unique }).then((res) => {
          if (res.status === "0") {
            window.location.replace(`${item.url}?unique=${unique}`);
          }
        });
      } else {
        location.href = item.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-list {
  width: 400px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 33.33%;
    box-sizing: border-box;
    padding: 10px;

    .card {
      padding-top: 20px;
      padding-bottom: 10px;
      display: grid;
      place-items: center;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 0 15px #e7e7e7;
      position: relative;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 0 25px #cccccc;
      }

      .img {
        display: block;
        width: 60px;
        height: 60px;
        object-fit: contain;
      }

      .name {
        font-size: 16px;
        color: #666666;
        line-height: 30px;
      }

      .badget {
        display: block;
        padding: 2px 6px;
        border-radius: 10px;
        background-color: #f56c6c;
        font-size: 12px;
        color: #ffffff;

        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .navigation-list {
    width: 100%;
  }
}
</style>
