import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import i18n from '@/locales/index';
import '@/scss/element-variables.scss';
import '@/scss/index.scss';

Vue.config.productionTip = false
// UI
Vue.use(ElementUI, {
  size: 'small'
});

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
