export const WEBSITE_LIST = [
  {
    key: "GOOGLE",
    name: "Google",
    url: "/google.html",
    animationData: require("@/assets/lottie-files/38870-google-logo-effect.json"),
  },
  {
    key: "FACEBOOK",
    name: "Facebook",
    url: "/facebook.html",
    animationData: require("@/assets/lottie-files/36872-facebook-icon.json"),
  },
  {
    key: "TIKTOK",
    name: "TikTok",
    url: "/tiktok.html",
    animationData: require("@/assets/lottie-files/49416-tiktok-icon.json"),
  },
  {
    key: "RESOURCE",
    name: "素材",
    url: "/material.html",
    animationData: require("@/assets/lottie-files/69-video-content-ta-dah.json"),
    badgetCount: 0,
  },
  {
    key: "MANAGEMENT",
    name: "公共管理",
    url: "/webUser.html",
    animationData: require("@/assets/lottie-files/2313-an-user.json"),
  },
  {
    key: "ANALYSIS",
    name: "数据分析",
    url: "/analysis.html",
    animationData: require("@/assets/lottie-files/19993-system-analytics-check-animation-loop.json"),
  },
  {
    key: "OVERSEAS",
    name: "海外业务",
    url: "/overseas.html",
    animationData: require("@/assets/lottie-files/global.json"),
  },
  {
    key: "TT-CLIENT",
    name: "TT客户",
    url: "/customer/index.html",
    animationData: require("@/assets/lottie-files/49416-tiktok-icon.json"),
  },
  {
    key: "OVS-CX",
    name: "TT GLOBAL",
    url: "/ovsCustomer.html",
    animationData: require("@/assets/lottie-files/49416-tiktok-icon.json"),
  },
];

export const getHost = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlEnv = urlParams.get("env");
  if (urlEnv === "lcl") {
    return process.env.VUE_APP_LCL_HOST;
  } else if (urlEnv === "cdj") {
    return process.env.VUE_APP_CDJ_HOST;
  } else if (urlEnv === "ljc") {
    return process.env.VUE_APP_LJC_HOST;
  }else{
    return process.env.VUE_APP_HOST;
  }
};
